import { StateCreator } from "zustand";

export interface TypingIndicatorStoreState {
  typingUsersByThreadId: Record<string, string[]>;
  addUserTyping: (threadId: string, userId: string, userName: string) => void;
  removeUserTyping: (threadId: string, userId: string) => void;
  typingUserNamesById: Record<string, string>;
}

export const createTypingIndicatorStore: StateCreator<
  TypingIndicatorStoreState
> = (set) => ({
  typingUsersByThreadId: {},
  typingUserNamesById: {},
  addUserTyping: (threadId: string, userId: string, userName: string) => {
    set((state) => {
      const existingUsers = state.typingUsersByThreadId[threadId] || [];
      if (!existingUsers.includes(userId)) {
        return {
          typingUsersByThreadId: {
            ...state.typingUsersByThreadId,
            [threadId]: [...existingUsers, userId],
          },
          typingUserNamesById: {
            ...state.typingUserNamesById,
            [userId]: userName,
          },
        };
      }
      return state;
    });
  },
  removeUserTyping: (threadId: string, userId: string) => {
    set((state) => {
      const existingUsers = state.typingUsersByThreadId[threadId];
      if (existingUsers && existingUsers.includes(userId)) {
        const updatedUsers = existingUsers.filter((id) => id !== userId);

        if (updatedUsers.length === 0) {
          const { [threadId]: _, ...rest } = state.typingUsersByThreadId;
          return { typingUsersByThreadId: rest };
        } else {
          return {
            typingUsersByThreadId: {
              ...state.typingUsersByThreadId,
              [threadId]: updatedUsers,
            },
          };
        }
      }
      return state; // Return unchanged state if the threadId or userId is not found
    });
  },
});
