import { create } from "zustand";
import { createThreadsStore, ThreadsStoreState } from "./Threads.store";
import {
  createOrganisationStore,
  OrganisationStoreState,
} from "./Organisation.store";
import { createUsersStore, UsersStoreState } from "./Users.store";
import { createAppStore, AppStoreState } from "./App.store";
import { createAuthStore, AuthStoreState } from "./Auth.store";
import {
  createSortingStore,
  SortingStoreState,
} from "@/app/store/Sorting.store";
import {
  createFiltersStore,
  FiltersStoreState,
} from "@/app/store/Filters.store";
import {
  createSearchQueryStore,
  SearchQueryStoreState,
} from "@/app/store/SearchQuery.store";
import {
  createTypingIndicatorStore,
  TypingIndicatorStoreState,
} from "@/app/store/TypingIndicator.store";
import { ChatsStoreState, createChatsStore } from "@/app/store/Chats.store";
import { createTagsStore, TagsStoreState } from "@/app/store/Tags.store";
import { createFeedsStore, FeedsStoreState } from "@/app/store/Feeds.store";
import {
  ChatBoxStoreState,
  createChatBoxStore,
} from "@/app/store/ChatBox.store";

export type StoreState = ThreadsStoreState &
  OrganisationStoreState &
  UsersStoreState &
  TagsStoreState &
  AppStoreState &
  AuthStoreState &
  SortingStoreState &
  FiltersStoreState &
  SearchQueryStoreState &
  TypingIndicatorStoreState &
  ChatsStoreState &
  FeedsStoreState &
  ChatBoxStoreState;

export const useAppStore = create<StoreState>()((...a) => ({
  ...createAppStore(...a),
  ...createThreadsStore(...a),
  ...createOrganisationStore(...a),
  ...createUsersStore(...a),
  ...createAuthStore(...a),
  ...createSortingStore(...a),
  ...createFiltersStore(...a),
  ...createSearchQueryStore(...a),
  ...createTypingIndicatorStore(...a),
  ...createChatsStore(...a),
  ...createTagsStore(...a),
  ...createFeedsStore(...a),
  ...createChatBoxStore(...a),
}));

// To support computed values from within the store, we need to export the store without `use` prefix to avoid hooks warning
export const globalStore = useAppStore;

const storeResetFns = new Set<() => void>();

export const resetAllStores = () => {
  storeResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialState = useAppStore.getState();
storeResetFns.add(() => {
  useAppStore.setState(initialState, true);
});
