import { isWindow } from "./utils/helper";

// Struct Server Endpoint
export const STRUCT_API_URL = process.env.NEXT_PUBLIC_STRUCT_SERVER_URL || "";
export const STRUCT_API_VERSION =
  process.env.NEXT_PUBLIC_STRUCT_API_VERSION || "v1";
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION || "main";

// auth
export const ID_SERVER_URL = process.env.NEXT_PUBLIC_ID_SERVER_URL;

export const isDev = process.env.NEXT_PUBLIC_APP_ENV === "dev";
if (isWindow) {
  // @ts-expect-error
  window.APP_VERSION = APP_VERSION;
}

export const isProduction = isWindow ? ID_SERVER_URL?.endsWith("ai") : false;

export const isApp =
  isDev || (isWindow && window.location.host.split(".")[0] === "app");

export const ENABLE_CHAT_HTTP_RESPONSE_INSERT = false;

// @ts-expect-error
export const isDesktopPlatform = isWindow ? !!window.__TAURI__ : false;
export const DESKTOP_APP_VERSION = isDesktopPlatform
  ? // @ts-expect-error
    window.DESKTOP_APP_VERSION
  : "";

export const enum EntityIDMap {
  Chat = "1",
  Thread = "2",
  Channel = "3",
  User = "4",
  Organisation = "5",
  Feed = "6",
}

export const FEED_VIEW_PLACEHOLDER = "What’s on your mind? @mention a channel or member and start a conversation";
export const CHAT_VIEW_PLACEHOLDER = "Send message...";
export const SLACK_ACTION_DISABLED =
  "Disabled. Use Slack to perform this action";
export const SLACK_FORK_ENABLE_TAG = ["struct/monthly", "struct/weekly"];
export const SLACK_LEVEL_3_PERMISSIONS = "https://app.struct.ai/slack-lvl3";
export const EXCLUDE_TRACKING_ORG_IDS = ["5L1f1"];
