import { StateCreator } from "zustand";
import {
  ReactionResponse,
  StructChat,
  StructChatResponse,
  StructThread,
  StructUser,
} from "@/app/types/Thread.type";
import { uniq, uniqBy } from "lodash";

export type ChatReply = {
  id: StructChat["id"];
  threadId: StructThread["id"];
};

export interface ChatsStoreState {
  chatsByThreadId: Record<StructChatResponse["id"], StructChat[]>;
  selectedChatsByThreadId: Record<StructChatResponse["id"], StructChat["id"][]>;
  setChats: (chats: StructChatResponse[], threadId: StructThread["id"]) => void;
  clearChats: () => void;
  clearSelectedChats: () => void;
  setSelectedChats: (
    threadId: StructThread["id"],
    chatIds: StructChatResponse["id"][],
  ) => void;
  setChatMessages: (
    threadId: StructThread["id"],
    newChatMessages: StructChatResponse[],
  ) => void;
  setChatMessage: (
    threadId: StructThread["id"],
    newChatMessage: StructChatResponse,
  ) => void;
  toggleSelectedChatMessage: (
    threadId: StructThread["id"],
    chatId: StructChatResponse["id"],
  ) => void;
  updateChatMessage: (
    threadId: StructThread["id"],
    message: StructChatResponse,
  ) => void;
  deleteChatMessage: (
    threadId: StructThread["id"],
    chatMessageId: StructChatResponse["id"],
  ) => void;

  // Chat Reaction
  setChatReaction: (
    threadId: StructThread["id"],
    chatMessageId: StructChatResponse["id"],
    reaction: ReactionResponse,
  ) => void;
  deleteChatReaction: (
    threadId: StructThread["id"],
    chatMessageId: StructChatResponse["id"],
    reaction: {
      name: string;
      user_id: string;
    },
  ) => void;

  // Chat Edit Mode
  chatIdInEditMode: StructChat["id"] | null;
  setChatIdInEditMode: (chatId: StructChat["id"]) => void;
  setLastChatInThreadInEditMode: (
    threadId: StructThread["id"],
    userId: StructUser["id"],
  ) => void;
  clearChatIdInEditMode: () => void;

  // Chat Reply
  chatReply: ChatReply | null;
  getChatReply: () => ChatReply | null;
  setChatReply: (chatReply: ChatReply) => void;
  clearChatReply: () => void;
}

const INITIAL_STATE = {};

const populateChats = (chats: StructChatResponse[]): StructChat[] => {
  return uniqBy(chats, "id").sort((a, b) => a.created_at - b.created_at);
};

export const createChatsStore: StateCreator<ChatsStoreState> = (set, get) => ({
  chatsByThreadId: INITIAL_STATE,
  selectedChatsByThreadId: {},
  chatIdInEditMode: null,
  chatReply: null,
  setChatReply: (chatReply) => set({ chatReply }),
  getChatReply: () => get().chatReply,
  clearChatReply: () => set({ chatReply: null }),
  setChats: (chats: StructChatResponse[], threadId: StructThread["id"]) =>
    set(({ chatsByThreadId }) => {
      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: populateChats(chats),
        },
      };
    }),
  setChatMessages: (threadId, newChatMessages: StructChatResponse[]) =>
    set(({ chatsByThreadId }) => {
      const currentChats = chatsByThreadId[threadId] || [];

      const updatedChats = [...currentChats, ...populateChats(newChatMessages)];

      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: uniqBy(updatedChats, "id"),
        },
      };
    }),

  toggleSelectedChatMessage: (threadId, chatId) =>
    set(({ selectedChatsByThreadId }) => {
      const currentSelectedChats = selectedChatsByThreadId[threadId] || [];

      const updatedSelectedChats = currentSelectedChats.includes(chatId)
        ? currentSelectedChats.filter((id) => id !== chatId)
        : [...currentSelectedChats, chatId];

      return {
        selectedChatsByThreadId: {
          ...selectedChatsByThreadId,
          [threadId]: uniq(updatedSelectedChats),
        },
      };
    }),

  clearSelectedChats: () => set({ selectedChatsByThreadId: INITIAL_STATE }),
  setSelectedChats: (
    threadId: StructThread["id"],
    chatIds: StructChat["id"][],
  ) =>
    set(({ selectedChatsByThreadId }) => {
      selectedChatsByThreadId[threadId] = chatIds;
      return {
        selectedChatsByThreadId,
      };
    }),

  setChatMessage: (threadId, newChatMessage: StructChatResponse) =>
    set(({ chatsByThreadId }) => {
      const currentChats = chatsByThreadId[threadId] || [];

      const isChatAlreadyExists = currentChats.find(
        (chat) => chat.id === newChatMessage.id,
      );

      if (isChatAlreadyExists) {
        return { chatsByThreadId };
      }

      const updatedChats = [
        ...currentChats,
        ...populateChats([newChatMessage]),
      ];
      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: updatedChats,
        },
      };
    }),
  updateChatMessage: (
    threadId,
    updatedChatData: {
      id: string;
      text: string;
      updated_at: number;
    },
  ) =>
    set(({ chatsByThreadId }) => {
      const threadChats = chatsByThreadId?.[threadId]?.flatMap((chat) => {
        // Skip (delete) the chat message if the text is " "
        if (chat.id === updatedChatData.id && updatedChatData.text === " ") {
          return [];
        }

        // Update the chat message if it's the one being updated
        if (chat.id === updatedChatData.id) {
          return populateChats([{ ...chat, ...updatedChatData }]);
        }

        // Keep the chat message if it's not the one being updated
        return [chat];
      });

      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: threadChats,
        },
      };
    }),
  deleteChatMessage: (
    threadId: StructThread["id"],
    chatMessageId: StructChatResponse["id"],
  ) =>
    set(({ chatsByThreadId }) => {
      const currentThread = chatsByThreadId[threadId] || [];

      const updatedThread = currentThread.filter(
        (chat) => chat.id !== chatMessageId,
      );

      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: updatedThread,
        },
      };
    }),
  setChatReaction: (threadId, chatMessageId, reaction) =>
    set(({ chatsByThreadId }) => {
      // Get the current thread
      const threadChats = chatsByThreadId[threadId] || [];

      // Map through the chat messages and update the reactions
      const updatedThreadChats = threadChats.map((chat) => {
        if (chat.id !== chatMessageId) {
          return chat;
        }

        // Ensure the reactions array exists
        if (!chat.reactions) {
          chat.reactions = [];
        }

        // Find the existing reaction if it exists
        const existingReaction = chat.reactions.find(
          (existingReaction) => existingReaction.name === reaction.name,
        );

        // Update the reaction if it exists
        if (existingReaction) {
          existingReaction.count += 1;
          existingReaction.user_ids?.push(reaction.user_id!);
          return chat;
        }

        // Add a new reaction if it doesn't exist
        chat.reactions.push({
          name: reaction.name,
          count: 1,
          user_ids: [reaction.user_id!],
          block: reaction.block,
          entity_map: reaction.entity_map || {},
        });

        return chat;
      });

      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: updatedThreadChats,
        },
      };
    }),

  deleteChatReaction: (threadId, chatMessageId, reaction) =>
    set(({ chatsByThreadId }) => {
      // Get the current thread
      const currentThread = chatsByThreadId[threadId] || [];

      // Map through the chat messages and update the reactions
      const updatedThread = currentThread.map((chat) => {
        if (chat.id !== chatMessageId || !chat.reactions) {
          return chat;
        }

        // Find the existing reaction if it exists
        const existingReaction = chat.reactions.find(
          ({ name }) => name === reaction.name,
        );

        if (!existingReaction) {
          return chat;
        }

        // Update the reaction
        existingReaction.count -= 1;
        existingReaction.user_ids = existingReaction.user_ids?.filter(
          (user_id) => user_id !== reaction.user_id,
        );

        // Remove the reaction if the count is zero
        if (existingReaction.count <= 0) {
          chat.reactions = chat.reactions.filter(
            ({ name }) => name !== reaction.name,
          );
        }

        return chat;
      });

      return {
        chatsByThreadId: {
          ...chatsByThreadId,
          [threadId]: updatedThread,
        },
      };
    }),
  setChatIdInEditMode: (chatId) => set({ chatIdInEditMode: chatId }),
  clearChatIdInEditMode: () => set({ chatIdInEditMode: null }),
  setLastChatInThreadInEditMode: (
    threadId: StructThread["id"],
    userId: StructUser["id"],
  ) =>
    set(({ chatsByThreadId }) => {
      const threadChats = chatsByThreadId[threadId];
      if (!threadChats || threadChats.length === 0) {
        return { chatIdInEditMode: null };
      }

      const lastChat = threadChats[threadChats.length - 1];

      return {
        chatIdInEditMode: lastChat.author_id === userId ? lastChat.id : null,
      };
    }),
  clearChats: () => set({ chatsByThreadId: INITIAL_STATE }),
});
