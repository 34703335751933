import { StateCreator } from "zustand";
import { StructFeed } from "@/app/types/Feed.type";

export interface FeedsStoreState {
  feeds: StructFeed[];
  threadCountByFeedId: Record<StructFeed["id"], number>;
  unreadThreadCountByFeedId: Record<StructFeed["id"], number>;
  selectedFeedIds: StructFeed["id"][] | [];
  toggleSelectedFeedIds: (feedIds: StructFeed["id"][]) => void;
  setThreadCountByFeedId: (
    feedId: StructFeed["id"],
    threadCount: number,
  ) => void;
  setUnreadThreadCountByFeedId: (
    feedId: StructFeed["id"],
    threadCount: number,
  ) => void;
  setFeeds: (tags: StructFeed[]) => void;
  setFeed: (feed: StructFeed) => void;
  getFeeds: () => StructFeed[];
  updateFeed: (partialFeed: Partial<StructFeed>) => void;
  deleteStoreFeed: (id: StructFeed["id"]) => void;
}

export const createFeedsStore: StateCreator<FeedsStoreState> = (
  set,
  get,
  api,
) => ({
  feeds: [],
  threadCountByFeedId: {},
  unreadThreadCountByFeedId: {},
  selectedFeedIds: [],
  toggleSelectedFeedIds: (feedIds: StructFeed["id"][]) => {
    set((state) => {
      const existingFeedIds = state.selectedFeedIds as string[];
      feedIds.forEach((feedId) => {
        if (existingFeedIds.includes(feedId)) {
          existingFeedIds.splice(existingFeedIds.indexOf(feedId), 1);
        } else {
          existingFeedIds.push(feedId);
        }
      });
      return {
        selectedFeedIds: existingFeedIds,
      };
    });
  },
  setThreadCountByFeedId: (feedId: StructFeed["id"], threadCount: number) => {
    set((state) => ({
      threadCountByFeedId: {
        ...state.threadCountByFeedId,
        [feedId]: threadCount,
      },
    }));
  },
  setUnreadThreadCountByFeedId: (
    feedId: StructFeed["id"],
    threadCount: number,
  ) => {
    set((state) => ({
      unreadThreadCountByFeedId: {
        ...state.unreadThreadCountByFeedId,
        [feedId]: threadCount,
      },
    }));
  },
  setFeeds: (feeds: StructFeed[]) => {
    set({ feeds });
  },
  setFeed: (feed: StructFeed) => {
    set((state) => ({
      feeds: [...state.feeds, feed],
    }));
  },
  updateFeed: (partialFeed: Partial<StructFeed>) => {
    set((state) => ({
      feeds: state.feeds.map((feed) =>
        feed.id === partialFeed.id ? { ...feed, ...partialFeed } : feed,
      ),
    }));
  },
  getFeeds: () => get().feeds,
  deleteStoreFeed: (id: StructFeed["id"]) => {
    set((state) => ({
      feeds: state.feeds.filter((feed) => feed.id !== id),
    }));
  },
});
