import { StructUser } from "@/app/types/Thread.type";
import { StateCreator } from "zustand";

export interface UsersStoreState {
  // Values
  onlineUserIds: StructUser["id"][];

  // Methods

  addOnlineUserId: (userId: StructUser["id"]) => void;
  removeOnlineUserId: (userId: StructUser["id"]) => void;
}

export const createUsersStore: StateCreator<UsersStoreState> = (set, get) => ({
  onlineUserIds: [],
  addOnlineUserId: (userId: StructUser["id"]) => {
    const currentOnlineUsers = get().onlineUserIds;
    if (!currentOnlineUsers.includes(userId)) {
      set({ onlineUserIds: [...currentOnlineUsers, userId] });
    }
  },
  removeOnlineUserId: (userId: StructUser["id"]) => {
    const currentOnlineUsers = get().onlineUserIds;
    set({ onlineUserIds: currentOnlineUsers.filter((id) => id !== userId) });
  },
});
