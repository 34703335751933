import { ID_SERVER_URL } from "@/app/constants";

export const generateOauthURL = ({
  idServer = ID_SERVER_URL || "https://id.struct.ai",
  redirect_to,
}: {
  idServer?: string;
  redirect_to: string;
}) => {
  return `${idServer}/?redirect_to=${redirect_to}`;
};

export const generateLogoutURL = ({
  hostname = ID_SERVER_URL || "https://id.struct.ai",
  redirect_to,
}: {
  hostname?: string;
  redirect_to: string;
}) => {
  return `${hostname}/logout?redirect_to=${redirect_to}`;
};
