import { StateCreator } from "zustand";

export const DEFAULT_FEED_ID = "6N1";
export const DEFAULT_SEARCH_FEED_ID = "6N0_SEARCH";

export const enum FeedModes {
  LIVE,
  FORUM,
  COMPACT,
}

export interface AppStoreState {
  isInitialized: boolean;
  setInitialized: (value: boolean) => void;
  feedMode: FeedModes;
  setFeedMode: (value: FeedModes) => void;
  isHeaderExpanded: boolean;
  setIsHeaderExpanded: (value: boolean) => void;
  isCommandMenuVisible: boolean;
  isCommandMenuVisibleViaMentionPanel: boolean;
  setIsCommandMenuVisible: (
    value: boolean,
    isViaMentionPanel?: boolean,
  ) => void;
  isRealtimeConnectionActive: boolean;
  setIsRealtimeConnectionActive: (value: boolean) => void;
  currentFeedId: string;
  setCurrentFeedId: (feedId?: string) => void;
  isUnreadFeedFilterActive: boolean;
  toggleUnreadFilter: (value: boolean) => void;
  feedTimeRange: number;
  setFeedTimeRange: (value: number) => void;
  shouldResync: boolean;
  setShouldResync: (value: boolean) => void;
  shouldUpgrade: boolean;
  setShouldUpgrade: (value: boolean) => void;
}

export const createAppStore: StateCreator<AppStoreState> = (set) => ({
  isInitialized: false,
  isUnreadFeedFilterActive: false,
  feedTimeRange: 0,
  feedMode: FeedModes.FORUM,
  isHeaderExpanded: true,
  isCommandMenuVisible: false,
  isCommandMenuVisibleViaMentionPanel: false,
  currentFeedId: DEFAULT_FEED_ID,
  isRealtimeConnectionActive: false,
  shouldResync: false,
  shouldUpgrade: false,
  setFeedTimeRange: (value) => {
    set({ feedTimeRange: value });
  },
  setIsHeaderExpanded: (value) => {
    set({ isHeaderExpanded: value });
  },
  setFeedMode: (feedMode: FeedModes) => {
    set({ feedMode });
  },
  setInitialized: (value: boolean) => {
    set({ isInitialized: value });
  },
  setIsRealtimeConnectionActive: (value: boolean) => {
    set({ isRealtimeConnectionActive: value });
  },
  setIsCommandMenuVisible: (value: boolean, isViaMentionPanel = false) => {
    set({
      isCommandMenuVisible: value,
      isCommandMenuVisibleViaMentionPanel: isViaMentionPanel,
    });
  },
  setCurrentFeedId: (feedId?: string) => {
    set({ currentFeedId: feedId });
  },
  toggleUnreadFilter(value: boolean) {
    set({ isUnreadFeedFilterActive: value });
  },
  setShouldResync: (value: boolean) => {
    set({ shouldResync: value });
  },

  setShouldUpgrade: (value: boolean) => {
    set({ shouldUpgrade: value });
  },
});
