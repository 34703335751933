import { CaretSortIcon } from "@radix-ui/react-icons";
import SortAscendingIcon from "../components/icons/SortAscendingIcon";
import SortDescendingIcon from "../components/icons/SortDescendingIcon";

export enum SortingOption {
  Relevance = "",
  UpdatedAscending = "updated_at:asc",
  UpdatedDescending = "updated_at:desc",
  CreatedAscending = "created_at:asc",
  CreatedDescending = "created_at:desc",
}

export const sortingNameMap = {
  [SortingOption.Relevance]: {
    label: "Relevance",
    icon: CaretSortIcon,
  },
  [SortingOption.UpdatedAscending]: {
    label: "Updated",
    icon: SortAscendingIcon,
  },
  [SortingOption.UpdatedDescending]: {
    label: "Updated",
    icon: SortDescendingIcon,
  },
  [SortingOption.CreatedAscending]: {
    label: "Created",
    icon: SortAscendingIcon,
  },
  [SortingOption.CreatedDescending]: {
    label: "Created",
    icon: SortDescendingIcon,
  },
};
