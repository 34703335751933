import { StateCreator } from "zustand";
import { Session } from "../types/session";
import { StructUser } from "../types/Thread.type";

// TODO: Add proper state and replace useApp
export interface AuthStoreState {
  session: Session | undefined;
  isAuthenticated: boolean;
  isAuthenticatedWithCorrectOrg: boolean;
  setSession: (session: Session) => void;
  updateSessionUser: (user: Partial<StructUser>) => void;
}

export const createAuthStore: StateCreator<AuthStoreState> = (set) => ({
  session: undefined,
  isAuthenticated: false,
  isAuthenticatedWithCorrectOrg: false,
  setSession: (session: Session) => {
    set({
      session,
      isAuthenticated: Boolean(session && session.email),
      isAuthenticatedWithCorrectOrg: !!session?.user?.id,
    });
  },
  updateSessionUser: (user: Partial<StructUser>) => {
    set((state) => {
      const session = state.session;
      if (!session) return { session };
      // @ts-expect-error
      session.user = { ...session.user, ...user };
      return { session };
    });
  },
});
