import { StructThread } from "@/app/types/Thread.type";
import { FeedFilter } from "./../types/FeedFitler.type";
import mitt, { Emitter } from "mitt";
import {
  FORCE_REFRESH_FEED,
  REFRESH_CHAT_TIMESTAMP,
  TRIGGER_FEED_FILTERS_MODAL,
  WS_PING_RECEIVED,
  REMOVE_FROM_FEED_KEEP_THREAD_OPEN,
  EMBED_THREAD_FROM_CMDK,
  TRIGGER_NOTIFICATION,
  REALTIME_RECONNECTION_TRIGGERED,
} from "../eventConstants";
import { CreateFeedPayload, StructFeed } from "../types/Feed.type";

type Events = {
  [TRIGGER_FEED_FILTERS_MODAL]?: {
    filters?: FeedFilter[];
    feedId?: string;
    name?: string;
    emoji?: string;
    bits?: CreateFeedPayload["bits"];
    entityMap?: StructFeed["entity_map"];
  };
  [FORCE_REFRESH_FEED]: { currentFeedId: string };
  [REFRESH_CHAT_TIMESTAMP]?: {};
  [WS_PING_RECEIVED]?: {};
  [REMOVE_FROM_FEED_KEEP_THREAD_OPEN]: { thread: StructThread | null };
  [EMBED_THREAD_FROM_CMDK]: {
    id: StructThread["id"];
    label: StructThread["subject"];
  };
  [TRIGGER_NOTIFICATION]: {
    title: string;
    options: NotificationOptions;
    threadId: string;
    isMentioned: boolean;
  };
  [REALTIME_RECONNECTION_TRIGGERED]: {};
};

const allEvents = new Map();

const events: Emitter<Events> = mitt<Events>(allEvents);

export const removeAllEvents = () => allEvents.clear();

export default events;
