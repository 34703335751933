import { StructOrganisation } from "@/app/types/Organisation.type";
import { StateCreator } from "zustand";

export interface OrganisationStoreState {
  organisation: StructOrganisation;
  setOrganisation: (organisation: StructOrganisation) => void;
  updateOrganisation: (data: Partial<StructOrganisation>) => void;
  clearOrganisation: () => void;
}

export const createOrganisationStore: StateCreator<OrganisationStoreState> = (
  set,
  get,
) => ({
  // @ts-ignore
  organisation: {},
  setOrganisation: (organisation: StructOrganisation) => {
    set({ organisation });
  },
  updateOrganisation: (data: Partial<StructOrganisation>) => {
    set({ organisation: { ...get().organisation, ...data } });
  },
  clearOrganisation: () => {
    // @ts-ignore
    set({ organisation: {} });
  },
});
