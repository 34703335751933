import { StateCreator } from "zustand";
import { SortingOption } from "@/app/types/Sorting.type";

export interface SortingStoreState {
  sorting: SortingOption;
  setSorting: (sorting: SortingOption) => void;
  toggleSorting: () => void;
  clearSorting: () => void;
}
const defaultSorting = SortingOption.Relevance;

const sortingToggleOptionMap = {
  [SortingOption.Relevance]: SortingOption.Relevance,
  [SortingOption.CreatedAscending]: SortingOption.CreatedDescending,
  [SortingOption.CreatedDescending]: SortingOption.CreatedAscending,
  [SortingOption.UpdatedAscending]: SortingOption.UpdatedDescending,
  [SortingOption.UpdatedDescending]: SortingOption.UpdatedAscending,
};

export const createSortingStore: StateCreator<SortingStoreState> = (set) => {
  return {
    sorting: defaultSorting,
    setSorting: (sorting) => {
      set({ sorting });
    },
    toggleSorting: () => {
      set(({ sorting }) => ({
        sorting: sortingToggleOptionMap[sorting],
      }));
    },
    clearSorting: () => {
      set({ sorting: defaultSorting });
    },
  };
};
