const IMAGE_SERVER_BASE_URL = `https://app.struct.ai/images`;

export const getAvatarImageURL = (avatar_id: string, size = 64) =>
  `${IMAGE_SERVER_BASE_URL}/${avatar_id}/${size}`;

export const getChatImageURL = (imageHash: string, quality: number = 512) =>
  `${IMAGE_SERVER_BASE_URL}/${imageHash}/${quality}`;

export const getReactionImageURL = (orgXid: string, emojiCode: string) =>
  `${IMAGE_SERVER_BASE_URL}/e-${orgXid}-${emojiCode}/64`;
