import { StateCreator } from "zustand";

export type Filters = {
  users: Array<string>;
  channels: Array<string>;
  tags: Array<string>;
};

export type FilterType = keyof Filters;
export interface FiltersStoreState {
  filters: Filters;
  setFilters: (filters: Filters) => void; // TODO: causes bad code, remove
  setFilter: (filterType: FilterType, id: string) => void;
  addFilter: (filterType: FilterType, id: string) => void;
  setFiltersByType: (filterType: FilterType, ids: string[]) => void;
  removeFilter: (filterType: FilterType, id: string) => void;
  clearFilters: () => void;
}

export const INIT_FILTERS: Filters = {
  users: [],
  channels: [],
  tags: [],
};

export const createFiltersStore: StateCreator<FiltersStoreState> = (
  set,
  get,
) => ({
  filters: INIT_FILTERS,
  setFilters: (filters) => {
    set({ filters });
  },
  setFilter: (filterType: FilterType, id: string) => {
    set((state: FiltersStoreState) => {
      const currentFilters = {
        ...state.filters,
        [filterType]: [id],
      };

      return { ...state, filters: currentFilters }; // Return the updated state object
    });
  },
  addFilter: (filterType: FilterType, id: string) => {
    set((state: FiltersStoreState) => {
      const currentFilters = {
        ...state.filters,
        [filterType]: [...state.filters[filterType], id],
      };

      return { ...state, filters: currentFilters }; // Return the updated state object
    });
  },
  setFiltersByType: (filterType: FilterType, ids: string[]) => {
    set((state: FiltersStoreState) => {
      const currentFilters = {
        ...state.filters,
        [filterType]: ids,
      };

      return { ...state, filters: currentFilters }; // Return the updated state object
    });
  },
  removeFilter: (filterType: FilterType, id: string) => {
    set((state: FiltersStoreState) => {
      const updatedFilters = {
        ...state.filters,
        [filterType]: state.filters[filterType].filter(
          (filter) => filter !== id,
        ),
      };

      return {
        ...state,
        filters: updatedFilters,
      };
    });
  },
  clearFilters: () => {
    set({ filters: INIT_FILTERS });
  },
});
