import { Read, StructThread } from "../types/Thread.type";

export const isWindow = typeof window !== "undefined";

export const getStateDeterministically = async (setState: any) => {
  return new Promise((resolve) => {
    setState((prevState: any) => {
      resolve(prevState);
      return prevState;
    });
  });
};

export const getThreadStatusBadge = (
  threadBits: StructThread["bits"],
  threadReadBits: Read["bits"],
) => {
  if (threadBits.hidden) {
    return "hiddenFromAll";
  } else if (threadReadBits.feed_hidden) {
    return "hidden";
  } else if (threadBits.feed_dm) {
    return "private";
  } else if (threadBits.feed_dm_self) {
    return "myEyesOnly";
  }
  return "";
};
