import { StateCreator } from "zustand";

export interface EditorCommands {
  insertMention: (mentionData: MentionData) => void;
  focus: () => void;
  blur: () => void;
  insertContent: (content: string) => void;
  clearContent: () => void;
  insertCharacter: (character: string) => void;
}

interface MentionData {
  id: string;
  label: string;
}

export interface DocNode {
  type: "doc";
  content: ParagraphNode[];
}

interface ParagraphNode {
  type: "paragraph";
  content: (TextNode | MentionNode)[];
}

export interface TextNode {
  type: "text";
  text: string;
}

export interface MentionNode {
  type: "mention";
  attrs: {
    id: string;
    label: string;
  };
}

export interface ChatBoxStoreState {
  initialContent: DocNode | null;
  setInitialContent: (content: DocNode | null) => void;
  chatBoxCommands: EditorCommands | null;
  setChatBoxCommands: (commands: EditorCommands) => void;
  getChatBoxCommands: () => EditorCommands | null;
}

export const createChatBoxStore: StateCreator<ChatBoxStoreState> = (
  set,
  get,
) => ({
  initialContent: null,
  chatBoxCommands: null,
  setInitialContent: (content) => set({ initialContent: content }),
  getChatBoxCommands: () => get().chatBoxCommands,
  setChatBoxCommands: (commands) => set({ chatBoxCommands: commands }),
});
