import { StateCreator } from "zustand";
import { StructTag } from "@/app/types/Tag.type";

export interface TagsStoreState {
  tags: StructTag[];
  setTags: (tags: StructTag[]) => void;
  setTag: (tag: string) => void;
  getTags: (prefix: string) => StructTag[];
}

export const createTagsStore: StateCreator<TagsStoreState> = (set, get) => ({
  tags: [],
  setTag: (tag: string) => {
    const tags = get().tags;
    tags.push({ tag, count: 1 });
    set({ tags });
  },
  setTags: (src: StructTag[]) => {
    const tags = src;
    // Let's sort them upfront, so we don't have to do it later.
    tags.sort((a, b) => {
      return b.count - a.count; // reverse sort by count
    });
    set({ tags });
  },
  getTags: (prefix: string) => {
    const out: StructTag[] = [];
    for (const t of get().tags) {
      if (t.tag.startsWith(prefix)) {
        out.push(t);
      }
      if (out.length >= 1000) {
        return out;
      }
    }
    // These are naturally sorted by most used first.
    return out;
  },
});
