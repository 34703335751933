import { useRouter } from "next/navigation";
import {
  generateLogoutURL,
  generateOauthURL,
} from "@/app/utils/authentication";
import { useAppStore } from "../store";

export const useApp = () => {
  const router = useRouter();

  const session = useAppStore((state) => state.session);
  const isSlackOrg = useAppStore(
    (state) => state.organisation?.bits?.org_slack,
  );

  const isDiscordOrg = useAppStore(
    (state) => state.organisation?.bits?.org_discord,
  );

  const isAuthenticatedWithCorrectOrg = useAppStore(
    (state) => state.isAuthenticatedWithCorrectOrg,
  );

  const logIn = () => {
    const currentURL = window.location.href;
    const redirectURL = `https://${
      new URL(currentURL).host
    }/auth?next_page=${currentURL}`;
    router.push(generateOauthURL({ redirect_to: redirectURL }));
  };

  const logOut = async () => {
    const currentURL = window.location.href;
    const redirectURL = `https://${new URL(currentURL).host}`;
    const hostname = `https://${window.location.hostname}`;
    router.push(generateLogoutURL({ hostname, redirect_to: redirectURL }));
  };

  const featureToggles = {
    canInviteMembers:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    canCreateChannel:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    canLeaveChannel:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    canJoinChannel:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    canAddMembersToChannel:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    canEditChannel:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    canCreateThreadFromChat:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    // disable chat replies in slack and discord orgs
    canReplyToChat:
      isAuthenticatedWithCorrectOrg && !isSlackOrg && !isDiscordOrg,
    // disable chat reactions in slack and discord orgs
    canReactToChat: isAuthenticatedWithCorrectOrg && !isDiscordOrg,
    // discord orgs cannot start conversation with members
    canStartChatWithMembers: !isDiscordOrg,

    // disable chat deletion for discord orgs
    canDeleteChat: isAuthenticatedWithCorrectOrg && Boolean(
      session?.isAdmin || session?.isOwner || session?.isModerator,
    ) && !isDiscordOrg,

    isLiveViewEnabled: true, //Boolean(organisation?.bits?.org_struct),
    isChatEnabled: isAuthenticatedWithCorrectOrg && !isDiscordOrg, //Boolean(organisation?.bits?.org_struct),
    isMembersPageEnabled: isAuthenticatedWithCorrectOrg, //Boolean(organisation?.bits?.org_struct),
    isChannelsPageEnabled: isAuthenticatedWithCorrectOrg, //Boolean(organisation?.bits?.org_struct),
    isOrganisationUserRole: Boolean(
      session?.isAdmin || session?.isOwner || session?.isModerator,
    ),
  };

  return {
    featureToggles,
    logIn,
    logOut,
  };
};
