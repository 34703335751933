import { v4 as uuidv4 } from "uuid";
let deviceId = Math.random();
if (typeof window !== "undefined") {
  deviceId =
    sessionStorage.deviceId && sessionStorage.closedLastTab !== "2"
      ? sessionStorage.deviceId
      : (sessionStorage.deviceId = uuidv4());
  sessionStorage.closedLastTab = "2";

  window.addEventListener("unload", function (e) {
    sessionStorage.closedLastTab = "1";
  });
  window.addEventListener("beforeunload", function (e) {
    sessionStorage.closedLastTab = "1";
  });
}

export { deviceId };
