const SortAscendingIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 0.80409239,3.017852 1.2588324,2.563112 v 8.01761 c 0,0.12568 0.04993,0.24621 0.1388,0.33508 0.08887,0.08887 0.2094,0.138797 0.33508,0.138798 0.12568,-1e-6 0.24621,-0.04993 0.33508,-0.138798 0.08887,-0.08887 0.13879,-0.2094 0.13879,-0.33508 v -8.01761 l 0.454749,0.45474 c 0.089292,0.08676 0.209138,0.13489 0.333631,0.13399 0.124493,-9e-4 0.243633,-0.05075 0.331665,-0.13878 0.088033,-0.08804 0.1378855,-0.20718 0.1387827,-0.33167 8.972e-4,-0.12449 -0.047233,-0.24434 -0.1339877,-0.33363 l -1.26367,-1.26365 c -0.04399,-0.044 -0.09622,-0.0789 -0.15371,-0.10280001 -0.05749,-0.0238 -0.1191,-0.036 -0.18133,-0.036 -0.06223,0 -0.12385,0.0122 -0.18134,0.036 -0.05749,0.0239 -0.10972,0.0588 -0.15371,0.10280001 L 0.13400239,2.347762 C 0.04724239,2.437052 -8.876118e-4,2.556902 1.2388197e-5,2.681392 9.023882e-4,2.805882 0.05076239,2.925022 0.13879239,3.013062 c 0.08803,0.08803 0.20717,0.13788 0.33167,0.13878 0.12449,9e-4 0.24433,-0.04723 0.33363,-0.13399 z"
      fill="#687076"
    />
    <path
      d="M 11.5261,2.20898 H 4.89184 c -0.12568,0 -0.24621,0.04993 -0.33508,0.1388 -0.08887,0.08887 -0.13879,0.2094 -0.13879,0.33508 0,0.12568 0.04992,0.24621 0.13879,0.33508 0.08887,0.08887 0.2094,0.13879 0.33508,0.13879 h 6.63426 c 0.1257,0 0.2462,-0.04992 0.3351,-0.13879 C 11.95,2.92907 12,2.80854 12,2.68286 12,2.55718 11.95,2.43665 11.8612,2.34778 11.7723,2.25891 11.6518,2.20898 11.5261,2.20898 Z"
      fill="#687076"
    />
    <path
      d="M 10.2624,4.42041 H 4.89184 c -0.12568,0 -0.24621,0.04993 -0.33508,0.1388 -0.08887,0.08886 -0.13879,0.2094 -0.13879,0.33508 0,0.12567 0.04992,0.24621 0.13879,0.33508 0.08887,0.08886 0.2094,0.13879 0.33508,0.13879 h 5.37056 c 0.1257,0 0.2462,-0.04993 0.3351,-0.13879 0.0889,-0.08887 0.1388,-0.20941 0.1388,-0.33508 0,-0.12568 -0.0499,-0.24622 -0.1388,-0.33508 -0.0889,-0.08887 -0.2094,-0.1388 -0.3351,-0.1388 z"
      fill="#687076"
    />
    <path
      d="M 8.99876,6.63184 H 4.89184 c -0.12568,0 -0.24621,0.04992 -0.33508,0.13879 -0.08887,0.08887 -0.13879,0.2094 -0.13879,0.33508 0,0.12568 0.04992,0.24621 0.13879,0.33508 0.08887,0.08887 0.2094,0.1388 0.33508,0.1388 h 4.10692 c 0.12568,0 0.24621,-0.04993 0.33508,-0.1388 0.08887,-0.08887 0.1388,-0.2094 0.1388,-0.33508 0,-0.12568 -0.04993,-0.24621 -0.1388,-0.33508 C 9.24497,6.68176 9.12444,6.63184 8.99876,6.63184 Z"
      fill="#687076"
    />
  </svg>
);
export default SortAscendingIcon;
