import { StateCreator } from "zustand";

export interface SearchQueryStoreState {
  searchQuery: string;
  formattedSearchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  setFormattedSearchQuery: (formattedSearchQuery: string) => void;
  clearSearchQuery: () => void;
}
const INITIAL_SEARCH_QUERY = "";

export const createSearchQueryStore: StateCreator<SearchQueryStoreState> = (
  set,
) => ({
  searchQuery: INITIAL_SEARCH_QUERY,
  formattedSearchQuery: INITIAL_SEARCH_QUERY,
  setSearchQuery: (searchQuery) => {
    set({ searchQuery });
  },
  setFormattedSearchQuery: (formattedSearchQuery) => {
    set({ formattedSearchQuery });
  },
  clearSearchQuery: () => {
    set({ searchQuery: INITIAL_SEARCH_QUERY });
  },
});
