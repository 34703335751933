import { STRUCT_API_URL } from "@/app/constants";
import { notification } from "antd";
import { globalStore } from "../store";
import { deviceId } from "../utils/sessionStorage";

export const structAPIFetchClient = (
  url: string,
  options: RequestInit = {},
  showError: boolean = true,
) => {
  const isFormData = options.body instanceof FormData;
  const defaultHeaders = isFormData
    ? {}
    : {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
        "x-struct-deviceid": deviceId,
      };

  options.credentials = "include";

  const headers = {
    ...defaultHeaders,
    ...options.headers,
  };

  return fetch(`${STRUCT_API_URL}${url}`, {
    ...options,
    redirect: "follow",
    // @ts-ignore
    headers,
  }).then((response) => {
    if (
      !response.ok &&
      ![401, 500, 504, 502].includes(response.status) &&
      showError &&
      globalStore.getState().isAuthenticatedWithCorrectOrg
    ) {
      if (response.status === 500) {
        notification.error({
          message: "Something went wrong. ",
          duration: 5,
        });
        return response;
      }
      response.text().then((text) => {
        notification.error({
          message: text,
          duration: 5,
        });
      });
    }
    return response;
  });
};
