const SortDescendingIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66133 8.98206L2.20659 9.4368V1.41919C2.20659 1.29351 2.15666 1.17298 2.06779 1.08411C1.97892 0.995238 1.85839 0.945313 1.73271 0.945312C1.60703 0.945313 1.4865 0.995238 1.39763 1.08411C1.30876 1.17298 1.25884 1.29351 1.25884 1.41919V9.4368L0.804091 8.98206C0.714799 8.8953 0.594953 8.84717 0.47046 8.84807C0.345967 8.84897 0.226827 8.89882 0.138795 8.98685C0.0507625 9.07489 0.000909522 9.19403 1.23219e-05 9.31852C-0.000884878 9.44301 0.0472457 9.56286 0.134 9.65215L1.39767 10.9158C1.44166 10.9598 1.49389 10.9947 1.55138 11.0186C1.60887 11.0424 1.67048 11.0546 1.73271 11.0546C1.79494 11.0546 1.85656 11.0424 1.91405 11.0186C1.97154 10.9947 2.02377 10.9598 2.06776 10.9158L3.33142 9.65215C3.41818 9.56286 3.46631 9.44301 3.46541 9.31852C3.46452 9.19403 3.41466 9.07489 3.32663 8.98685C3.2386 8.89882 3.11946 8.84897 2.99496 8.84807C2.87047 8.84717 2.75063 8.8953 2.66133 8.98206Z"
      fill="#687076"
    />
    <path
      d="M11.5261 2.20898H4.89184C4.76616 2.20898 4.64563 2.25891 4.55676 2.34778C4.46789 2.43665 4.41797 2.55718 4.41797 2.68286C4.41797 2.80854 4.46789 2.92907 4.55676 3.01794C4.64563 3.10681 4.76616 3.15673 4.89184 3.15673H11.5261C11.6518 3.15673 11.7723 3.10681 11.8612 3.01794C11.95 2.92907 12 2.80854 12 2.68286C12 2.55718 11.95 2.43665 11.8612 2.34778C11.7723 2.25891 11.6518 2.20898 11.5261 2.20898Z"
      fill="#687076"
    />
    <path
      d="M10.2624 4.42041H4.89184C4.76616 4.42041 4.64563 4.47034 4.55676 4.55921C4.46789 4.64807 4.41797 4.76861 4.41797 4.89429C4.41797 5.01996 4.46789 5.1405 4.55676 5.22937C4.64563 5.31823 4.76616 5.36816 4.89184 5.36816H10.2624C10.3881 5.36816 10.5086 5.31823 10.5975 5.22937C10.6864 5.1405 10.7363 5.01996 10.7363 4.89429C10.7363 4.76861 10.6864 4.64807 10.5975 4.55921C10.5086 4.47034 10.3881 4.42041 10.2624 4.42041Z"
      fill="#687076"
    />
    <path
      d="M8.99876 6.63184H4.89184C4.76616 6.63184 4.64563 6.68176 4.55676 6.77063C4.46789 6.8595 4.41797 6.98003 4.41797 7.10571C4.41797 7.23139 4.46789 7.35192 4.55676 7.44079C4.64563 7.52966 4.76616 7.57959 4.89184 7.57959H8.99876C9.12444 7.57959 9.24497 7.52966 9.33384 7.44079C9.42271 7.35192 9.47264 7.23139 9.47264 7.10571C9.47264 6.98003 9.42271 6.8595 9.33384 6.77063C9.24497 6.68176 9.12444 6.63184 8.99876 6.63184Z"
      fill="#687076"
    />
  </svg>
);
export default SortDescendingIcon;
