import { Button, Flex, AlertDialog } from "@radix-ui/themes";
import React, { ReactElement, useState } from "react";

interface ConfirmationDialogProps {
  title: string;
  onCancel: (event?: React.MouseEvent) => void;
  onConfirm: (event?: React.MouseEvent) => void;
  primaryLabel?: string;
  secondaryLabel?: string;
}

const ConfirmationDialog = ({
  title,
  onCancel,
  onConfirm,
  primaryLabel = "Delete",
  secondaryLabel = "Cancel",
}: ConfirmationDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async (event: React.MouseEvent) => {
    setIsLoading(true);
    onConfirm(event);
  };

  return (
    <AlertDialog.Root defaultOpen>
      <AlertDialog.Content style={{ maxWidth: 450 }}>
        <AlertDialog.Title>{title}</AlertDialog.Title>

        <Flex gap="3" mt="4" justify="end">
          <Button
            disabled={isLoading}
            variant="soft"
            color="gray"
            onClick={onCancel}
          >
            {secondaryLabel}
          </Button>
          <Button
            disabled={isLoading}
            variant="solid"
            color="red"
            onClick={handleConfirm}
          >
            {isLoading ? "Loading..." : primaryLabel}
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default ConfirmationDialog;
